<template>
  <div class="layout">
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :success-duration="1000"
      :success-text="succeedText"
    >
      <div v-for="(item, index) in newslist" :key="index">
        <p class="send-time" v-if="index === 0">{{ item.created_date }}</p>
        <p
          class="send-time"
          v-else-if="item.created_time - newslist[index - 1].created_time > 120"
        >
          {{ item.created_date }}
        </p>
        <div
          :class="[
            { 'left-content-info': item.is_my !== 0 },
            { 'right-content-info': item.is_my === 0 },
          ]"
        >
          <img :src="groupData.logo" class="head-img left-head-img" />
          <div class="text-content">
            <div class="control-name">
              <span class="user-name">{{
                item.is_my === 0 ? myData.nickname : groupData.name
              }}</span>
            </div>
            <div class="send-content">
              <span
                class="left-hint color"
                :class="{ 'hint-color': item.status * 1 === 1 }"
              >
                {{ item.status * 1 === 1 ? "已读" : "未读" }}
              </span>
              <!-- 聊天内容分类 -->
              <ContentText
                :info="item.text"
                :type="item.text_type"
              ></ContentText>
              <ContentImg :info="item.text" :type="item.text_type"></ContentImg>
              <span
                class="right-hint color"
                :class="{ 'hint-color': item.status * 1 === 1 }"
              >
                {{ item.status * 1 === 1 ? "已读" : "未读" }}
              </span>
            </div>
          </div>
          <img :src="myData.head_img" class="head-img right-head-img" />
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import ContentText from "../content-text/content-text";
import ContentImg from "../content-img/content-img";
export default {
  name: "dialogue-box",
  props: {
    newslist: {
      type: Array,
    },
    myData: {
      type: Object,
    },
    groupData: {
      type: Object,
    },
  },
  components: {
    ContentText,
    ContentImg,
  },
  data() {
    return {
      isSee: true,
      contentText: "",
      contentImg:
        "https://img1.baidu.com/it/u=251049146,3905767606&fm=26&fmt=auto",
      refreshing: false,
      succeedText: "加载成功",
    };
  },
  mounted() {},
  methods: {
    onRefresh() {
      this.refreshing = true;
      console.log("=========触发历史消息=========");
      this.$emit("getPastNews");
    },
    closeRefresh(time) {
      this.refreshing = false;
      if (time <= 0) {
        this.succeedText = "没有更多了";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.send-time {
  width: 100%;
  font-size: 15px;
  text-align: center;
  color: #6f6f6f;
}
// 公共样式
.head-img {
  flex-shrink: 0;
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.send-content {
  position: relative;
  display: table;
  margin-top: 7px;
  background-color: #fff;
  max-width: 272px;
  border-radius: 6px;
  padding: 9px;
  box-sizing: border-box;
  .left-hint {
    position: absolute;
    top: 50%;
    left: -39px;
    transform: translateY(-50%);
  }
  .right-hint {
    position: absolute;
    top: 50%;
    right: -39px;
    transform: translateY(-50%);
  }
  .color {
    color: #ed301d;
  }
  .hint-color {
    color: #aaa;
  }
}
// 右边
.right-content-info {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
  .text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 13px;
    .control-name {
      display: flex;
      justify-content: flex-end;
      .user-name {
        font-size: 18px;
        color: #6f6f6f;
      }
    }
  }
  .left-head-img {
    display: none;
  }
  .right-hint {
    display: none !important;
  }
}
// 左边
.left-content-info {
  display: flex;
  margin-bottom: 25px;
  .text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 13px;
    .control-name {
      display: flex;
      .user-name {
        font-size: 18px;
        color: #6f6f6f;
      }
    }
  }
  .right-head-img {
    display: none;
  }
  .left-hint {
    display: none !important;
  }
}
</style>