<template>
  <div v-if="isShow" class="layout">
    <img src="~@/assets/home/icon-Its-franchisees.png" class="goods-img" />
    <div class="goods-detail">
      <span class="goods-title">家庭会员分红套餐2</span>
      <div class="bottom-info">
        <div class="price">
          <span>￥</span>
          <span class="integer">{{ "88" }}</span>
          <span class="fractional-part">{{ ".00" }}</span>
        </div>
        <div class="send-button">发送客服</div>
      </div>
    </div>
    <van-icon
      name="cross"
      class="close-btn"
      color="#aaaaaa"
      size="24"
      @click="closeGoods"
    />
  </div>
</template>

<script>
export default {
  name: "goods",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    closeGoods() {
      this.$emit('goodsCallback')
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  position: relative;
  display: flex;
  background-color: #fff;
  border-radius: 11px;
  margin: 14px 13px;
  padding: 13px;
  .close-btn {
    position: absolute;
    right: 14px;
    top: 14px;
  }
  .goods-img {
    flex-shrink: 0;
    display: block;
    width: 66px;
    height: 66px;
    border: 9px;
    margin-right: 13px;
  }
  .goods-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .goods-title {
      font-size: 15px;
      font-weight: 500;
    }
  }
  .bottom-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .price {
      font-size: 13px;
      color: #ed301d;
      .integer {
        font-weight: 500;
        font-size: 19px;
      }
    }
    .send-button {
      width: 68px;
      height: 29px;
      background: linear-gradient(90deg, #f86464, #f00000);
      border-radius: 29px;
      line-height: 29px;
      text-align: center;
      color: #fff;
      font-size: 13px;
    }
  }
}
</style>