<template>
  <div class="information-page">
    <NavBar class="nav-bar">
      <div slot="title">{{ groupData.name }}</div>
    </NavBar>
    <DialogueBox
      class="dialogue-box"
      ref="dialogue"
      :newslist="list"
      :myData="myData"
      :groupData="groupData"
      @getPastNews="getPastNews"
    ></DialogueBox>
    <div class="operate-btns">
      <Goods :is-show="showGoods" @goodsCallback="goodsCallback"></Goods>
      <div class="input-subfield">
        <div class="input-way" @click="inputChange" v-if="false">
          <svg-icon
            :icon-class="keyboard"
            :className="keyboardClass"
          ></svg-icon>
        </div>
        <div class="input-back">
          <div class="key-speak" v-if="inputStatus !== 'yuyin'">按住 说话</div>
          <input type="text" v-model="inputVal" class="input" v-else />
          <!-- <van-icon
            name="smile-o"
            class="smiling-face"
            size="30"
            color="#707070"
          /> -->
        </div>
        <div class="operate">
          <div class="more-operate" v-if="inputVal === ''" @click="moreOperate">
            <van-icon :name="plusSign" color="#fff" size="20" />
          </div>
          <div class="send-info" @click="sendMsg" v-else>发送</div>
        </div>
      </div>
      <!-- 更多功能 -->
      <div class="more-class" ref="moreClass">
        <MoreOperate @getimgUrl="sendImg"></MoreOperate>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import DialogueBox from "../components/dialogue-box/dialogue-box";
import Goods from "../components/goods/goods";
import MoreOperate from "../components/more-operate/more-operate";
import socket from "@/common/js/socket";
import { Toast } from "vant";
export default {
  name: "information",
  components: {
    NavBar,
    Goods,
    DialogueBox,
    MoreOperate,
  },
  data() {
    return {
      goodsId: 0,
      usedId: 0,
      shopId: this.$route.query.shopId || 0,
      uId: this.$route.query.uId === undefined ? 0 : this.$route.query.uId,
      groupType: this.$route.query.groupType || 1,
      groupId:
        this.$route.query.groupId === undefined ? 0 : this.$route.query.groupId,
      list: [],
      inputVal: "",
      myData: {
        id: 0,
        head_img: "",
        nickname: "",
      },
      groupData: {
        id: 0,
        logo: "",
        m_id: "",
        name: "",
      },
      isBottom: false,
      endId: 0,
      inputStatus: "yuyin",
      showGoods: false,
      backColor: "",
      readIn: "",
      plusSign: "plus",
    };
  },
  watch: {
    inputVal(n, o) {
      if (n.length >= 240) {
        this.inputVal = this.inputVal.slice(0, this.inputVal.length - 1);
        Toast.fail("超过字符长度限制");
      }
    },
  },
  computed: {
    keyboard() {
      if (this.inputStatus === "chinese") {
        return "jianpan";
      } else {
        return "yuyin";
      }
    },
    keyboardClass() {
      return "keyboard-icon";
    },
    color() {
      if (this.showGoods) {
        return "#F2F2F2";
      } else if (!this.showGoods && this.inputStatus === "chinese") {
        return "#fff";
      } else {
        return "";
      }
    },
    inputColor() {
      if (this.inputStatus === "chinese") {
        return "#F8F8F8";
      } else {
        return "#fff";
      }
    },
  },
  mounted() {
    // console.log("shopId------", this.shopId);
    // console.log("groupType------", this.groupType);
    // console.log("uId------", this.uId);
    //事件注册
    let _that = this;
    //接收推送消息
    socket.on("getImInfo", (ret) => {
      if (ret.status * 1 === 1) {
        _that.myData = ret.data.user;
        _that.groupData = ret.data.group;
        _that.groupId = ret.data.group.id;
        _that.uId = ret.data.group.to_id;
        _that.setImList(ret.data.items);
        _that.setRead();
      } else {
        Toast.fail(ret.msg);
      }
      this.scrollToBottom();
    });
    //绑定获取消息通知
    socket.on("sendMsg", (ret) => {
      if (ret.status * 1 === 1) {
        _that.setSendMsg(ret.data);
      } else {
        Toast.fail(ret.msg);
      }
    });
    //获取历史聊天信息
    socket.on("getMsgList", (ret) => {
      if (ret.status * 1 === 1) {
        _that.setImList(ret.data);
      } else {
        Toast.fail(ret.msg);
      }
    });
    //将消息标记为已读
    socket.on("setRead", (ret) => {

      if (ret.status * 1 === 1 && ret.data.look_id * 1 !== _that.myData.id * 1) {
        _that.list.forEach((val, key) => {
          val.status = 1;
        });
      }
    });
    this.getImInfo();
    this.isIphone();
  },
  methods: {
    isIphone() {
      let operateBox = document.querySelector(".input-subfield");
      let container = document.querySelector(".dialogue-box");
      let isIphoneX =
        window.devicePixelRatio &&
        window.devicePixelRatio === 3 &&
        window.screen.width === 375 &&
        testUA("iPhone");
      if (isIphoneX) {
        check();
        window.onscroll = check();
      }
      function check() {
        // 处理lib-flexible放大viewport的情况
        let scale = window.innerWidth / window.screen.width;
        // 部分浏览器在滚动页面时会显示/隐藏工具栏，影响视口高度。在有底部工具栏的情况下，不做iPhoneX的fix。100为经验值
        if (window.screen.height - window.innerHeight / scale < 100) {
          document.body.classList.add("fix-iphonex-bottom");
          operateBox.style.paddingBottom = 34 + "px";
          container.style.paddingBottom = 86 + "px";
        } else {
          document.body.classList.remove("fix-iphonex-bottom");
          container.style.paddingBottom = 58 + "px";
        }
      }
      function testUA(str) {
        return navigator.userAgent.indexOf(str) > -1;
      }
    },
    setSendMsg(data) {
      if (data.mId * 1 === this.myData.id * 1) {
        data.is_my = 0;
        this.setRead();
      } else {
        data.is_my = 1;
      }
      // console.log(
      //   "data.group_id--",
      //   data.group_id,
      //   "===",
      //   "this.groupId--",
      //   this.groupId
      // );
      if (data.groupId === this.groupId) {
        data.text_type= data.textType;
        this.list.push(data);
      }
      //验证是否在底部，在部就刷新到最底页面
      this.scrollToBottom();
    },
    setRead() {
      socket.emit("setRead", {
        group_id: this.groupId,
        look_id: this.myData.id,
      });
    },
    setImList(list = []) {
      if (list.length <= 0 && this.$refs.dialogue !== undefined) {
        this.$refs.dialogue.closeRefresh(list.length);
      }
      let _that = this;
      list.forEach((val, key) => {
        if (val.m_id * 1 === _that.myData.id * 1) {
          val.is_my = 0;
        } else {
          val.is_my = 1;
        }
        _that.list.unshift(val);
        _that.endId = val.id;
      });
      if (_that.$refs.dialogue !== undefined) {
        _that.$refs.dialogue.closeRefresh();
      }
    },
    getImInfo() {
      //获取Im聊天组信
      socket.emit("getImInfo", {
        group_id: this.groupId,
        shop_id: this.shopId,
        handle_id: this.uId,
        group_type: this.groupType,
      });
    },
    getPastNews() {
      //获取对应组聊天信息
      socket.emit("getMsgList", { end_id: this.endId, group_id: this.groupId });
    },
    sendMsg() {
      //发送文本消息
      if (this.inputVal === "") {
        Toast.fail("请输入聊天内容");
      }
      this.sendSocketMsg(this.inputVal, 1);
      this.inputVal = "";
    },
    sendImg(imgUrl) {
      this.imgUrl = imgUrl;
      if (this.imgUrl === "") {
        Toast.fail("请选择图片");
        return false;
      }
      this.sendSocketMsg(this.imgUrl, 2);
      this.imgUrl = "";
      this.moreOperate();
    },
    sendSocketMsg(text, textType) {
      //发送消息
      socket.emit("sendMsg", {
        group_id: this.groupId,
        text_type: textType,
        handle_id: this.uId,
        text: text,
      });
    },
    goodsCallback() {
      // this.showGoods = false;
    },
    inputRestrict() {
      // if (this.inputVal.length >= 10) {
      //   this.inputVal = this.inputVal.slice(0, this.inputVal.length - 1);
      //   console.log(this.inputVal);
      //   Toast.fail("字符长度限制240");
      // }
    },
    moreOperate() {
      let operateBox = document.querySelector(".input-subfield");

      if (this.plusSign === "plus") {
        operateBox.style.paddingBottom = 3 + "px";
        this.$refs.moreClass.classList.remove("more-class");
        this.$refs.moreClass.className = "more-class-height";
        this.isBottom = true;
        this.scrollToBottom();
        this.plusSign = "cross";
      } else {
        this.$refs.moreClass.classList.remove("more-class-height");
        this.$refs.moreClass.className = "more-class";
        this.isBottom = false;
        this.plusSign = "plus";
        this.isIphone();
      }
    },
    scrollToBottom() {
      // console.log("=========执行滚动底部==========");
      this.$nextTick(function () {
        setTimeout(() => {
          let container = document.querySelector(".dialogue-box");
          container.scrollTop = container.scrollHeight;
        }, 100);
      });
    },
  },
};
</script>

<style lang="less" scoped>
@inputHeight: 40px;
.dialogue-box {
  padding: 58px 0 58px 0;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
}
.information-page {
  position: relative;
  .nav-bar {
    background-color: #fff;
    border-bottom: 1px solid rgb(245, 245, 245);
  }
}
.operate-btns {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f2f2f2;
  border-top: 1px solid #e4e4e4;
}
.input-subfield {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 3px 13px;
  // box-sizing: border-box;
  .keyboard-icon {
    font-size: 22px;
    color: red;
  }
  .input-back {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: @inputHeight;
    border-radius: 50px;
    background-color: #fff;
    padding: 0 22px;
    margin: 0 12px;
    font-size: 15px;
    overflow: hidden;
    .input {
      border: none;
      width: 90%;
      background-color: transparent;
    }
    .key-speak {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      line-height: @inputHeight;
      &:active {
        background-color: rgb(221, 221, 221);
      }
    }
    .smiling-face {
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
    }
  }
  .more-operate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    background: linear-gradient(143deg, #f86464 0%, #f00000 100%);
    border-radius: 50%;
  }
  .send-info {
    width: 40px;
    height: 30px;
    background: linear-gradient(180deg, #fc5656 0%, #f70606 100%);
    border-radius: 9px;
    text-align: center;
    line-height: 30px;
    color: #fff;
  }
}
.more-class {
  height: 0;
  width: 100%;
  background-color: #f5f5f5;
  transition: all 0.3s;
}
.more-class-height {
  height: 320px;
  width: 100%;
  background-color: #f5f5f5;
  transition: all 0.3s;
}
.dialogue-bottom {
  margin-bottom: 320px !important;
}
.fix-iphonex-bottom {
  padding-bottom: 34px;
}
</style>
