<template>
  <!-- <van-uploader
    :after-read="afterRead"
    :before-read="beforeRead"
    v-model="fileList"
    upload-icon="plus"
    multiple
    class="uploader"
    @delete="onDelete"
  /> -->
  <van-uploader :after-read="afterRead" multiple>
    <!-- <van-button icon="plus" type="primary">上传文件</van-button> -->
    <svg-icon :icon-class="'img'" :className="'baby'"></svg-icon>
  </van-uploader>
</template>

<script>
import oss from "ali-oss";
import { getAliSts } from "@/api/common";
import { Toast } from "vant";

export default {
  name: "oss-img",
  // props: {
  //   emptyImg: {},
  // },
  data() {
    return {
      aliOss: "",
      fileList: [],
      imgArr: [],
    };
  },
  // watch: {
  //   emptyImg(newVal, oldVal) {
  //     this.fileList = newVal;
  //     this.fileList.forEach((item) => {
  //       this.imgArr.push(item.url);
  //     });
  //   },
  // },
  async mounted() {
    let ret = await getAliSts({});
    if (ret.code * 1 === 1) {
      // console.log("----------------");
      this.aliOss = new oss({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: "oss-cn-chengdu",
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: ret.data.AccessKeyId,
        accessKeySecret: ret.data.AccessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: ret.data.SecurityToken,
        refreshSTSToken: async () => {
          // 向您搭建的STS服务获取临时访问凭证。
          let ret = await getAliSts({});
          if (ret.code * 1 === 1) {
            return {
              accessKeyId: ret.info.accessKeyId,
              accessKeySecret: ret.info.accessKeySecret,
              stsToken: ret.info.stsToken,
            };
          } else {
            Toast.fail(ret.msg);
          }
        },
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: 800000,
        // 填写Bucket名称。
        bucket: "jhnewshop",
      });
      // console.log(this.aliOss);
    } else {
      Toast.fail(ret.msg);
    }
  },
  methods: {
    // beforeRead(fileArr) {
    //   if (Array.isArray(fileArr)) {
    //     if (fileArr.length > 1) {
    //       Toast.fail("图片仅支持单张上传");
    //       this.fileList = [];
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    //   if (fileArr.type !== "image/jpeg" && fileArr.type !== "image/png") {
    //     Toast.fail("文件格式不正确");
    //     this.fileList = [];
    //     return false;
    //   }
    //   return true;
    // },
    async afterRead(object) {
      let file = object.file;
      if (this.aliOss === "") {
        Toast.fail("上传主键正在初始化中....");
        return false;
      }
      object.status = "uploading";
      object.message = "上传中....";
      let path = this.getFilPathName(file);
      const r1 = await this.aliOss.put(path, file);
      if (r1.url !== undefined) {
        // this.timer("done", object);
        // let url = r1.url;
        // this.imgArr.push(
        //   `${url}?x-oss-process=image/auto-orient,1/quality,q_85`
        // );
        this.$emit("getImages", r1.url);
      } else {
        // this.timer("failed", object);
      }
    },
    // onDelete(fileObj, data) {
    //   this.imgArr.splice(data.index, 1);
    //   // let imgArr = [];
    //   // this.fileList.forEach((item) => {
    //   //   imgArr.push(item.url);
    //   // });
    //   this.$emit("getImages", this.imgArr);
    // },
    getFilPathName(file) {
      let date = new Date();
      let path =
        "user/" +
        date.getYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        "/" +
        date.getMilliseconds();
      path += file.name;
      return path;
    },
    // timer(str, object) {
    //   setTimeout(() => {
    //     if (str === "done") {
    //       object.status = "done";
    //       object.message = "上传成功";
    //     } else {
    //       object.status = "failed";
    //       object.message = "上传失败";
    //       return;
    //     }
    //   }, 1000);
    // },
  },
};
</script>

<style lang="less" scoped>
.baby {
  width: 73px;
  height: 73px;
}
</style>
