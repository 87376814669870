<template>
  <div class="text" v-if="type * 1 === 1">{{ info }}</div>
</template>

<script>
export default {
  name: "content-text",
  props: ["info", "type"],
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.text {
  font-size: 15px;
  color: #000;
  max-width: 254px;
  word-wrap: break-word;
}
</style>