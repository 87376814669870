<template>
  <div class="more-operate">
    <!-- <div class="operate" @click="operateFn()">
      <svg-icon :icon-class="'baby'" :className="'baby'"></svg-icon>
      <span>宝贝</span>
    </div>
    <div class="operate" @click="operateFn()">
      <svg-icon :icon-class="'camera'" :className="'baby'"></svg-icon>
      <span>宝贝</span>
    </div> -->
    <div class="operate" @click="operateFn(3)">
      <!-- <svg-icon :icon-class="'img'" :className="'baby'"></svg-icon> -->
      <OssImg @getImages="getImages"></OssImg>
      <span class="text-font">相册</span>
    </div>
    <!-- <div class="operate" @click="operateFn()">
      <svg-icon :icon-class="'video'" :className="'baby'"></svg-icon>
      <span>宝贝</span>
    </div>
    <div class="operate" @click="operateFn()">
      <svg-icon :icon-class="'file'" :className="'baby'"></svg-icon>
      <span>宝贝</span>
    </div>
    <div class="operate" @click="operateFn()">
      <svg-icon :icon-class="'location'" :className="'baby'"></svg-icon>
      <span>宝贝</span>
    </div> -->
  </div>
</template>

<script>
import OssImg from "../oss-img/oss-img.vue";
export default {
  name: "more-operate",
  components: {
    OssImg,
  },
  data() {
    return {};
  },
  methods: {
    operateFn(num) {
      switch (num) {
        case 3:
          break;
        default:
          break;
      }
    },
    getImages(imgUrl) {
      // oss获取的路径，传递至父组件
      this.$emit("getimgUrl", imgUrl);
    },
  },
};
</script>

<style lang="less" scoped>
.baby {
  width: 73px;
  height: 73px;
}
.more-operate {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  // grid-template-rows: 73px 73px 73px 73px;
  // display: flex;
  // flex-wrap: wrap;
  padding: 22px 22px;
  .operate {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 13px;
    span {
      margin-top: 7px;
    }
  }
  .text-font {
    font-size: 13px;
  }
}
</style>