<template>
  <div v-if="type * 1 === 2">
    <img :src="info" class="img" @click="clickImg(info)" />
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  name: "content-img",
  props: ["info",'type'],
  data() {
    return {};
  },
  methods: {
    clickImg(img) {
      ImagePreview({
        images: [img],
        showIndex: false,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.img {
  display: block;
  max-width: 120px;
  height: auto;
}
</style>